import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CryptojsService {
    encryptData(data: any) {
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                const vbJsonString = {
                    ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
                };
                if (cipherParams.iv) {
                    vbJsonString['iv'] = cipherParams.iv.toString()
                };
                if (cipherParams.salt) {
                    vbJsonString['s'] = cipherParams.salt.toString()
                };
                return JSON.stringify(vbJsonString);
            },
            parse: function (jsonStr) {
                const vbJsonParse = JSON.parse(jsonStr);
                const cipherParams = CryptoJS.lib.CipherParams.create({
                    ciphertext: CryptoJS.enc.Base64.parse(vbJsonParse.ct)
                });
                if (vbJsonParse.iv) {
                    cipherParams['iv'] = CryptoJS.enc.Hex.parse(vbJsonParse.iv)
                }
                if (vbJsonParse['s']) {
                    cipherParams.salt = CryptoJS.enc.Hex.parse(vbJsonParse.s)
                }
                return cipherParams;
            }
        }

        const secret_key = environment.cryptoSecretKey;

        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secret_key, { format: CryptoJSAesJson }).toString();
        console.log(`typeof encrypted:`, typeof encrypted);
        console.log(`encrypted:`, encrypted);

        //const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypted, secret_key, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
        //console.log(`decrypted:`, decrypted);

        return encrypted;
    }
}
